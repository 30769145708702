<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >

        <b-form-group
          label="Nombre"
          label-for="nombre"
        >
          <b-form-input
            id="nombre"
            v-model="dataRegister.nombre"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <b-form-group
          label="Seleccione Destino"
          label-for="destino"
        >
          <v-select
            id="destino"
            v-model="destinoSel"
            :reduce="m => m.idDestinoTuristico"
            label="nombre"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="destinos"
            :clearable="false"
          />
        </b-form-group>

        <b-form-group
          label="Fecha de Inicio"
          label-for="periodoInicio"
        >
          <flat-pickr
            id="periodoInicio"
            v-model="dataRegister.periodoInicio"
            class="form-control"
            :config="configDP"
          />
        </b-form-group>

        <b-form-group
          label="Fecha de Fin"
          label-for="periodoFin"
        >
          <flat-pickr
            id="periodoFin"
            v-model="dataRegister.periodoFin"
            class="form-control"
            :config="configDP"
          />
        </b-form-group>

        <b-form-group
          label="Departamento"
          label-for="dpto"
        >
          <v-select
            v-model="dptoSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dptos"
            @input="getUbigeos('prov', dptoSel.slice(0, 2))"
          />
        </b-form-group>

        <b-form-group
          label="Provincia"
          label-for="prov"
        >
          <v-select
            v-model="provSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="provs"
            @input="getUbigeos('dist', provSel.slice(0, 4))"
          />
        </b-form-group>

        <b-form-group
          label="Distrito"
          label-for="dist"
        >
          <v-select
            v-model="distSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dists"
          />
        </b-form-group>

        <b-form-group
          label="Descripcion Festividad"
          label-for="descripcion"
        >
          <b-form-textarea
            id="descripcion"
            v-model="dataRegister.descripcion"
            rows="4"
          />
        </b-form-group>

        <b-form-group
          label="Actividades Festividad"
          label-for="actividades"
        >
          <b-form-textarea
            id="actividades"
            v-model="dataRegister.actividades"
            rows="4"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import config from '@/services/config'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    // const { baseURL, contextBO } = config
    // const pathMultimedia = ref(`${baseURL}${contextBO}/api/v1/multimedia`)
    const titleForm = ref('')
    const dataRegister = ref({})
    const destinos = ref([])
    const destinoSel = ref(null)
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])

    const resetForm = () => {
      dataRegister.value = {}
      destinoSel.value = ''
    }

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('catalogo/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            destinos.value = response.items
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const saveForm = async () => {
      if (dataRegister.value.nombre === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar nombre de la festividad', type: 'danger' })
        return
      }

      /* if (dataRegister.value.descripcion === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar descripción de la festividad', type: 'danger' })
        return
      } */

      if (dataRegister.value.periodoInicio === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar fecha de inicio de la festividad', type: 'danger' })
        return
      }

      if (dataRegister.value.periodoFin === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar fecha de fin de la festividad', type: 'danger' })
        return
      }

      if (destinoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Destino' } } })
        return
      }

      if (dptoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar departamento' } } })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar provincia' } } })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar distrito' } } })
        return
      }

      let service = 'festividades/FESTIVIDADES_CREATE'

      if (props.formType === 'edit') {
        service = 'festividades/FESTIVIDADES_UPDATE'
      }

      const start = moment(dataRegister.value.periodoInicio).format('MM-DD-YYYY')
      const end = moment(dataRegister.value.periodoFin).format('MM-DD-YYYY')

      const startDate = new Date(start)
      const finalDate = new Date(end)

      dataRegister.value.periodoInicio = startDate
      dataRegister.value.periodoFin = finalDate
      dataRegister.value.destino = {
        idDestinoTuristico: destinoSel.value,
      }
      dataRegister.value.ubigeo = distSel.value

      await store.dispatch(service, dataRegister.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          // emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = async val => {
      resetForm()
      emit('update:is-toggle-sidebar', val)
      getDestinos()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Festividad'
        getUbigeos('dpto', '')
        getUbigeos('prov', dptoSel.value.slice(0, 2))
      }
      if (props.formType === 'edit') {
        dataRegister.value = props.dataEdit
        dataRegister.value.ubigeo = props.dataEdit.ubigeo
        destinoSel.value = dataRegister.value.destino.idDestinoTuristico

        dptoSel.value = `${dataRegister.value.ubigeo.slice(0, 2)}0000`
        await getUbigeos('dpto', '')
        await getUbigeos('prov', dataRegister.value.ubigeo.slice(0, 2))
        provSel.value = `${dataRegister.value.ubigeo.slice(0, 4)}00`
        await getUbigeos('dist', dataRegister.value.ubigeo.slice(0, 4))
        distSel.value = dataRegister.value.ubigeo

        titleForm.value = 'Editar Festividad'
      }
    }

    return {
      resetForm,
      dataRegister,
      saveForm,
      toggleSidebar,
      titleForm,
      destinos,
      destinoSel,
      getDestinos,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      configDP,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
